import React from 'react';
import CollapseSection from '../../components/CollapseSection';
import CollapseItem from '../../components/CollapseSection/CollapseItem';
import Layout from '../../components/Layout';
import { useTranslationUtil } from '../../hooks/useTranslationUtil';
import FacAgriAnimalPDF from './assets/2567-1-agri-01.pdf';
import FacAgriRuralPDF from './assets/2567-1-agri-02.pdf';
import FacAgriRuralMasterPDF from './assets/2567-2-agri-01.pdf';
import FacAgriRuralPHDPDF from './assets/2567-2-agri-02.pdf';
import FacEngineerIndusMasterPDF from './assets/2567-2-eng-03.pdf';
import FacEngineerIndusPHDPDF from './assets/2567-2-eng-02.pdf';
import FacEngineerIndusMgtMasPDF from './assets/2567-2-eng-01.pdf';
import FacBaInnoPDF from './assets/2567-2-ba-01.pdf';
import FacBaFinPDF from './assets/2567-2-ba-02.pdf';
import FacGradMentalPDF from './assets/2567-2-grad-05.pdf';
import FacGradChinesePDF from './assets/2567-2-grad-03.pdf';
import FacGradSportPDF from './assets/2567-2-grad-04.pdf';
import FacGradForensicPDF from './assets/2567-2-grad-01.pdf';
import FacGradForensic2PDF from './assets/2567-2-grad-11.pdf';
import FacGradInScPDF from './assets/2567-2-grad-06.pdf';
import FacEngineerComMasterPDF from './assets/2567-2-eng-04.pdf';
import FacEngineerComPHDPDF from './assets/2567-2-eng-05.pdf';
import FacEngineerComPHDPDFaddon from './assets/2567-2-eng-06.pdf';
import FacEngineerDataPDF from './assets/2567-2-eng-07.pdf';
import FacEngineerLogisticPDF from './assets/2567-2-eng-09.pdf';
import FacEngineerElecDocPDF from './assets/2567-1-eng-06.pdf';
import FacEngineerElecMasPDF from './assets/2567-1-eng-07.pdf';
import FacEngineerElecPDF from './assets/2567-2-eng-08.pdf';
import FacMedScPDF from './assets/2567-1-med-01.pdf';
import FacScBioEthPDF from './assets/2567-1-sc-01.pdf';
import FacScApplyBioPDF from './assets/2567-1-sc-02.pdf';
import FacScBioPDF from './assets/2567-1-sc-03.pdf';
import FacScBioPHDPDF from './assets/2567-2-sc-05.pdf';
import FacScBioMasPDF from './assets/2567-2-sc-06.pdf';
import FacScChemPDF from './assets/2567-2-sc-09.pdf';
import FacScPhysicPDF from './assets/2567-2-sc-08.pdf';
import FacScStatPDF from './assets/2567-2-sc-02.pdf';
import FacScMathPDF from './assets/2567-2-sc-03.pdf';
import FacScEnviPDF from './assets/2567-2-sc-07.pdf';
import FacScNanoPDF from './assets/2567-1-sc-08.pdf';
import FacScGeoInterPDF from './assets/2567-2-sc-01.pdf';
import FacScGeoPDF from './assets/2567-2-sc-04.pdf';
import FacVetSciencePDF from './assets/2567-1-vet-01.pdf';
import FacPHDocPDF from './assets/2567-2-ph-01.pdf';
import FacPHMasterPDF from './assets/2567-2-ph-02.pdf';
import FacAMSRegPDF from './assets/2567-2-ams-01.pdf';
import FacAMSInterPDF from './assets/2567-2-ams-02.pdf';
import FacDentPDF from './assets/2567-2-dent-01.pdf';
import FacEDUAllPDF from './assets/2567-2-edu-01.pdf';
import FacEDUAdminPDF from './assets/2567-1-edu-02.pdf';
import FacEDUMathPDF from './assets/2567-1-edu-03.pdf';
import FacEDUResearchPDF from './assets/2567-1-edu-04.pdf';
import FacEDUTeachPDF from './assets/2567-1-edu-05.pdf';
import FacEDUEdPDF from './assets/2567-1-edu-06.pdf';
import FacRIHESHealthScPDF from './assets/2567-2-rihes-01.pdf';
import FacBemiBemiPDF from './assets/2567-2-bemi-01.pdf';
import FacEconPDF from './assets/2567-1-2-econ01.pdf';
import FacEconRegPDF from './assets/2567-1-2-econ02.pdf';
import FacEconReg2PDF from './assets/2567-1-2-econ12.pdf';
import FacEconPTPDF from './assets/2567-1-2-econ03.pdf';
import FacSocialSocAntPDF from './assets/2567-2-social-01.pdf';
import FacSocialGeoPDF from './assets/2567-1-social-02.pdf';
import FacSocialGeoinfoPDF from './assets/2567-1-social-03.pdf';
import FacPharPharPDF from './assets/2567-2-phar-01.pdf';
import FacNurseNursePDF from './assets/2567-2-nurse-01.pdf';
import FacFineArtsCulturePDF from './assets/2567-2-finearts-01.pdf';
import FacFineArtsMusicDocPDF from './assets/2567-2-finearts-02.pdf';
import FacFineArtsMusicMasPDF from './assets/2567-2-finearts-03.pdf';
import FacFineArtsAnDDocPDF from './assets/2567-1-finearts-04.pdf';
import FacFineArtsAnDMasPDF from './assets/2567-2-finearts-05.pdf';
import FacFineArtsVisualPDF from './assets/2567-2-finearts-06.pdf';
import FacICDIInnoDocPDF from './assets/2567-2-icdi-01.pdf';
import FacICDIInnoMasPDF from './assets/2567-2-icdi-02.pdf';
import FacCAMTcamtPDF from './assets/2567-2-camt-01.pdf';
import FacMarinInnoPDF from './assets/2567-2-marin-01.pdf';
import FacPolSCPublicAdminPDF from './assets/2567-2-polsc-01.pdf';
import FacPolSCPolGovPDF from './assets/2567-2-polsc-02.pdf';
import FacSPPPHDPDF from './assets/2567-2-spp-01.pdf';
import FacSPPMasPDF from './assets/2567-2-spp-02.pdf';
import FacSPPDDPPDF from './assets/2567-2-spp-03.pdf';


import CustomContainer from '../../components/CustomContainer';
import PageTitleContainer from '../../components/PageTitleContainer';
import CollapseSectionContainer from '../../components/CollapseSection/CollapseSectionContainer';
import { QUALIFY_APPLICATION } from '../../constants/route-paths';

const QualifyApplicationPage = () => {
  const { t } = useTranslationUtil();
  return (
    <Layout>
      <CustomContainer>
        <PageTitleContainer>{t('QualifyApplication')}</PageTitleContainer>

        <CollapseSectionContainer>
          <CollapseSection title={t('AnnOnApp')}>
          <CollapseItem pdfUrl={'https://admission.grad.cmu.ac.th/admissions/indexeng.php?p=102&tab=1&page=1&idp=40'}>  {t('AnnOnApply')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacDent')}>
            <CollapseItem pdfUrl={FacDentPDF}> {t('FacDent')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacEcon')}>
            <CollapseItem pdfUrl={FacEconPDF}> {t('FacEconMasInter')}</CollapseItem>
            <CollapseItem pdfUrl={FacEconRegPDF}> {t('FacEconMasReg')}</CollapseItem>
            <CollapseItem pdfUrl={FacEconPTPDF}> {t('FacEconMasPT')}</CollapseItem>
            <CollapseItem pdfUrl={FacEconReg2PDF}> {t('FacEconMasReg2')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacPH')}>
            <CollapseItem pdfUrl={FacPHDocPDF}> {t('FacPHDoc')}</CollapseItem>
            <CollapseItem pdfUrl={FacPHMasterPDF}> {t('FacPHMaster')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacEngineer')}>
            <CollapseItem pdfUrl={FacEngineerIndusMgtMasPDF}> {t('FacEngineerIndusMgtMas')}</CollapseItem>
            <CollapseItem pdfUrl={FacEngineerIndusPHDPDF}> {t('FacEngineerIndusDoc')}</CollapseItem>
            <CollapseItem pdfUrl={FacEngineerIndusMasterPDF}> {t('FacEngineerIndusMaster')}</CollapseItem>
            <CollapseItem pdfUrl={FacEngineerComMasterPDF}> {t('FacEngineerComMaster')}</CollapseItem>
            <CollapseItem pdfUrl={FacEngineerComPHDPDF}> {t('FacEngineerComPHD')}</CollapseItem>
            <CollapseItem pdfUrl={FacEngineerComPHDPDFaddon}> {t('FacEngineerComPHDaddon')}</CollapseItem>
            <CollapseItem pdfUrl={FacEngineerDataPDF}> {t('FacEngineerData')}</CollapseItem>
            <CollapseItem pdfUrl={FacEngineerElecPDF}> {t('FacEngineerElec')}</CollapseItem>
            <CollapseItem pdfUrl={FacEngineerLogisticPDF}> {t('FacEngineerLogistic')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacGrad')}>
            <CollapseItem pdfUrl={FacGradForensicPDF}> {t('FacGradForensic')}</CollapseItem>
            <CollapseItem pdfUrl={FacGradForensic2PDF}> {t('FacGradForensic2')}</CollapseItem>
            <CollapseItem pdfUrl={FacGradChinesePDF}> {t('FacGradChinese')}</CollapseItem>
            <CollapseItem pdfUrl={FacGradSportPDF}> {t('FacGradSport')}</CollapseItem>
            <CollapseItem pdfUrl={FacGradMentalPDF}> {t('FacGradMental')}</CollapseItem>
            <CollapseItem pdfUrl={FacGradInScPDF}> {t('FacGradInSc')}</CollapseItem>
            <CollapseItem pdfUrl={FacGradMentalPDF}> {t('FacGradMental')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacSc')}>
            <CollapseItem pdfUrl={FacScGeoInterPDF}> {t('FacScGeoInter')}</CollapseItem>
            <CollapseItem pdfUrl={FacScStatPDF}> {t('FacScStat')}</CollapseItem>
            <CollapseItem pdfUrl={FacScMathPDF}> {t('FacScMath')}</CollapseItem>
            <CollapseItem pdfUrl={FacScGeoPDF}> {t('FacScGeo')}</CollapseItem>
            <CollapseItem pdfUrl={FacScBioPHDPDF}> {t('FacScBioPHD')}</CollapseItem>
            <CollapseItem pdfUrl={FacScBioMasPDF}> {t('FacScBioMas')}</CollapseItem>
            <CollapseItem pdfUrl={FacScEnviPDF}> {t('FacScEnvi')}</CollapseItem>
            <CollapseItem pdfUrl={FacScPhysicPDF}> {t('FacScPhysic')}</CollapseItem>
            <CollapseItem pdfUrl={FacScChemPDF}> {t('FacScChem')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>

        <CollapseSectionContainer>
          <CollapseSection title={t('FacSocial')}>
            <CollapseItem pdfUrl={FacSocialSocAntPDF}> {t('FacSocialSocAnt')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacMarin')}>
            <CollapseItem pdfUrl={FacMarinInnoPDF}> {t('FacMarinInno')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacICDI')}>
            <CollapseItem pdfUrl={FacICDIInnoDocPDF}> {t('FacICDIInnoDoc')}</CollapseItem>
            <CollapseItem pdfUrl={FacICDIInnoMasPDF}> {t('FacICDIInnoMas')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacAgri')}>
            <CollapseItem pdfUrl={FacAgriRuralMasterPDF}> {t('FacAgriRuralMaster')}</CollapseItem>
            <CollapseItem pdfUrl={FacAgriRuralPHDPDF}> {t('FacAgriRuralPHD')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>

        <CollapseSectionContainer>
          <CollapseSection title={t('FacRIHES')}>
            <CollapseItem pdfUrl={FacRIHESHealthScPDF}> {t('FacRIHESHealthSc')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacBemi')}>
            <CollapseItem pdfUrl={FacBemiBemiPDF}> {t('FacBemiBemi')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 
        <CollapseSectionContainer>
          <CollapseSection title={t('FacPhar')}>
            <CollapseItem pdfUrl={FacPharPharPDF}> {t('FacPharPhar')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacPolSC')}>
            <CollapseItem pdfUrl={FacPolSCPublicAdminPDF}> {t('FacPolSCPublicAdmin')}</CollapseItem>
            <CollapseItem pdfUrl={FacPolSCPolGovPDF}> {t('FacPolSCPolGov')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacNurse')}>
            <CollapseItem pdfUrl={FacNurseNursePDF}> {t('FacNurseNurse')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacCAMT')}>
            <CollapseItem pdfUrl={FacCAMTcamtPDF}> {t('FacCAMTcamt')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacAMS')}>
            <CollapseItem pdfUrl={FacAMSRegPDF}> {t('FacAMSReg')}</CollapseItem>
            <CollapseItem pdfUrl={FacAMSInterPDF}> {t('FacAMSInter')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacFineArts')}>
            <CollapseItem pdfUrl={FacFineArtsMusicDocPDF}> {t('FacFineArtsMusicDoc')}</CollapseItem>
            <CollapseItem pdfUrl={FacFineArtsMusicMasPDF}> {t('FacFineArtsMusicMas')}</CollapseItem>
            <CollapseItem pdfUrl={FacFineArtsVisualPDF}> {t('FacFineArtsVisual')}</CollapseItem>
            <CollapseItem pdfUrl={FacFineArtsCulturePDF}> {t('FacFineArtsCulture')}</CollapseItem>
            <CollapseItem pdfUrl={FacFineArtsAnDMasPDF}> {t('FacFineArtsAnDMas')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacSPP')}>
            <CollapseItem pdfUrl={FacSPPPHDPDF}> {t('FacSPPPHD')}</CollapseItem>
            <CollapseItem pdfUrl={FacSPPMasPDF}> {t('FacSPPMas')}</CollapseItem>
            <CollapseItem pdfUrl={FacSPPDDPPDF}> {t('FacSPPDDP')}</CollapseItem>

          </CollapseSection>
        </CollapseSectionContainer> 

        <CollapseSectionContainer>
          <CollapseSection title={t('FacBa')}>
            <CollapseItem pdfUrl={FacBaInnoPDF}> {t('FacBaInno')}</CollapseItem>
            <CollapseItem pdfUrl={FacBaFinPDF}> {t('FacBaFin')}</CollapseItem>
          </CollapseSection>
        </CollapseSectionContainer>  

        <CollapseSectionContainer>
          <CollapseSection title={t('FacEDU')}>
            <CollapseItem pdfUrl={FacEDUAllPDF}> {t('FacEDUAll')}</CollapseItem>


          </CollapseSection>
        </CollapseSectionContainer> 

  }
        </CustomContainer>
    </Layout>
  );
};

export default QualifyApplicationPage;
